<template>
  <el-container>
    <!-- 分析报告生成 -->
    <div class="w-100">
      <div class="fxbgsc pb-10">
        <el-form label-width="100px" class="form-box">
          <el-form-item label="分析方案">
            <el-select class="w-200" v-model="where.plan_id">
              <el-option
                :value="row.pid"
                v-for="(row, index) in plans"
                :key="index"
                :label="row.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分析维度" class="fxwd">
            <el-button
              v-for="(row, index) in types"
              :key="index"
              @click="typeChange(row.value)"
              :class="typeClass[row.value]"
              >{{ row.name }}</el-button
            >
          </el-form-item>
          <!-- 热点分析 -->
          <el-form-item label="媒体类型" v-show="where.type == 'event'">
            <el-select class="w-200" v-model="where.media">
              <el-option label="网媒" value="网媒"></el-option>
              <el-option label="微博" value="微博"></el-option>
              <el-option
                :disabled="where.type == 'event'"
                label="微信"
                value="微信"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分析时间">
            <el-date-picker
              v-model="where.span"
              :clearable="false"
              :picker-options="{
                disabledDate: dateRange,
                shortcuts: pickerOptions.shortcuts
              }"
              :default-time="['00:00:00', '23:59:59']"
              :editable="false"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报告名称">
            <el-input
              class="w-310"
              placeholder="请输入分析报告名称"
              v-model.trim="where.name"
              :maxlength="titleMaxLen"
              show-word-limit
            >
            </el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input
              type="text"
              placeholder="（非必填）请输入副标题"
              v-model="where.subtitle"
              maxlength="30"
              show-word-limit
              class="w-430"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="主题颜色">
            <div class="theme_colors lh-30">
              <div v-for="item in themeList" :key="item.label">
                <el-radio v-model="where.theme_color" :label="item.label">{{
                  item.name
                }}</el-radio>
                <span
                  :style="{ background: item.color }"
                  class="item-theme-preview"
                ></span>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <el-row>
          <el-col class="xxzl">
            <ul>
              <li>
                <i class="iconfont icon-pinglun"></i>
                <p>
                  信 息 总 量：
                  <span v-if="forecastState">计算中</span>
                  <span v-else>{{ forecast.info_count }}条</span>
                </p>
              </li>
              <li>
                <i class="iconfont icon-shijian"></i>
                <p>
                  预计生成时间：
                  <span v-if="forecastState">计算中</span>
                  <span v-else>
                    {{ forecast.end_date_text }}
                  </span>
                </p>
              </li>
              <li>
                <span class="iconfont icon-Loading Rotation"></span>
                <p>
                  等待生成报告数：
                  <span v-if="forecastState">计算中</span>
                  <span v-else>{{ forecast.wait_count }}</span>
                </p>
              </li>
              <li v-show="!forecastState && forecast.prompt">
                <p>
                  <span>{{ forecast.prompt }}</span>
                </p>
              </li>
            </ul>
          </el-col>
        </el-row>
        <div class="button-group">
          <el-button type="primary" round :disabled="true" v-if="!allowSubmit"
            >生成</el-button
          >
          <el-button
            type="primary"
            round
            class="selected"
            @click="generate"
            v-loading="loading"
            :disabled="loading"
            v-else
            >生成报告</el-button
          >
          <el-button round @click="cancel">重置</el-button>
        </div>
        <el-row class="pt_10 hide" v-show="where.type == 'event'">
          <el-col class="w-110">
            <span class="lh-32 fw-b">关键词锁定</span>
          </el-col>
          <el-col :span="21">
            <div>
              <el-input
                placeholder="多个关键词请用英文逗号隔开"
                class="w-350"
                v-model.trim="where.focus.must"
              ></el-input>
              <span class="pad_lr_5">关键词关系：</span>
              <el-radio-group class="yhh" v-model="where.focus.op">
                <el-radio label="and">与</el-radio>
                <el-radio label="or">或</el-radio>
              </el-radio-group>
            </div>
            <div class="mar-t-10">
              <span class="pad_r_10">排除</span>
              <el-input
                placeholder="多个关键词请用英文逗号隔开"
                class="w-316"
                v-model.trim="where.focus.not"
              ></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 可用分析数达上限 -->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="fxs_limit_Dialog.visible"
        class="Dialog_style2 fxdsx"
      >
        <el-row class="w-100 dp_box">
          <el-col :span="13">
            <div class="img">可用分析数已达上限</div>
          </el-col>
          <el-col :span="11">
            <p>
              您购买的<strong>{{ bundle.bundle_name }}</strong>
            </p>
            <p class="fw-b">
              可用分析数已达上限：<span class="red"
                >{{ bundle.analyses.used }}次</span
              >
            </p>
            <p class="mar-t-10">请联系客服人员添购更多</p>
            <p>分析数</p>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </el-container>
</template>
<script type="application/javascript">
import { themeList, pickerOptions } from '@/utils/constants';
import { dateRangeDisabled, parseTime } from '@/utils/helpers.js';
import { forecast, submit } from '@/api/analysis';
import { mapGetters } from 'vuex';
export default {
  name: 'analysis-generate',
  components: {},
  data() {
    return {
      pickerOptions,
      titleMaxLen: 20,
      themeList,
      fxs_limit_Dialog: { visible: false },
      types: [
        { name: '热点分析', value: 'hot' },
        { name: '事件传播分析', value: 'event' },
        { name: '观点分析', value: 'opinion' },
        { name: '情感分析', value: 'emotion' }
      ],
      typeClass: { hot: 'checked', event: '', opinion: '', emotion: '' },
      where: {
        name: '',
        type: 'hot',
        media: '网媒',
        plan_id: '',
        span: [],
        subtitle: '',
        focus: { must: '', op: 'and', not: '' },
        theme_color: themeList[0].label
      },
      oldWhere: {},
      loading: false,
      forecast: {
        info_count: 0,
        wait_count: '请填选分析参数',
        end_date: 0,
        wait_date: 0,
        wait_date_text: '请填选分析参数',
        end_date_text: '请填选分析参数',
        prompt: ''
      },
      forecastState: false,
      allowSubmit: false,
      timeoutFlag: null
    };
  },
  watch: {
    'where.plan_id': function(pid) {
      this.rename();
    },
    'where.type': function(type) {
      this.rename();
    },
    where: {
      handler(data) {
        if (this.forecastState) return false;
        this.allowSubmit = false;
        let params = JSON.parse(JSON.stringify(data));
        delete params.focus;
        if (data.plan_id && _.size(data.name) >= 1 && _.size(data.span) == 2) {
          this.forecastState = true;
          params.span = params.span.join(',');
          params.media =
            params.type === 'hot' ? ['网媒', '微博', '微信'] : params.media;
          forecast(params)
            .then(res => {
              if (res.data.state) {
                let result = res.data.data.data;
                let waitM = parseInt(result.wait_date / 60);
                let endM = parseInt(result.end_date / 60);
                if (waitM < 1) {
                  result.wait_date_text = result.wait_date + '秒';
                } else if (waitM < 60) {
                  result.wait_date_text = waitM + '分钟';
                } else {
                  let waityu = result.wait_date % 3600;
                  let waitMinuteText =
                    parseInt(waityu / 60) >= 1
                      ? parseInt(waityu / 60) + '分钟'
                      : '';
                  result.wait_date_text =
                    parseInt(result.wait_date / 60 / 60) +
                    '小时' +
                    waitMinuteText;
                }
                if (endM < 1) {
                  result.end_date_text = result.end_date + '秒';
                } else if (endM < 60) {
                  result.end_date_text = endM + '分钟';
                } else {
                  let endyu = result.end_date % 3600;
                  let waitMinuteText =
                    parseInt(endyu / 60) >= 1
                      ? parseInt(endyu / 60) + '分钟'
                      : '';
                  result.end_date_text =
                    parseInt(result.end_date / 60 / 60) +
                    '小时' +
                    waitMinuteText;
                }
                this.forecast = result;
                this.allowSubmit = result.analyse_enable; // 返回状态是否可以生成
              } else {
                this.$message.error(res.data.error);
              }
              this.forecastState = false;
            })
            .catch(res => {
              this.$message.error('获取预估分析时间失败，服务错误');
            });
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['bundle', 'plans'])
  },
  destroyed() {
    if (this.timeoutFlag) {
      clearTimeout(this.timeoutFlag);
    }
  },
  mounted() {
    this.oldWhere = JSON.parse(JSON.stringify(this.where));
  },
  methods: {
    rename() {
      let plan = _.find(this.plans, { pid: this.where.plan_id });
      this.where.name = '';
      if (plan !== undefined) {
        let type = _.find(this.types, { value: this.where.type });
        this.where.name = `${plan.name}-${type.name}`;
      }
    },
    cancel() {
      this.where = JSON.parse(JSON.stringify(this.oldWhere));
      this.typeChange(this.oldWhere.type);
    },
    generate() {
      if (this.bundle.analyses.remain <= 0) {
        this.fxs_limit_Dialog.visible = true;
        return false;
      }
      let where = JSON.parse(JSON.stringify(this.where));
      where.media =
        where.type === 'hot' ? ['网媒', '微博', '微信'] : where.media;
      if (where.type != 'event' && where.type != 'hot') delete where.media;
      if (where.type != 'event') delete where.focus;
      if (where.name.trim().length < 1) {
        this.$message.warning('请填写报告名称1~20个字符长度');
        return false;
      }
      if (!where.plan_id) {
        this.$message.warning('请选择分析方案');
        return false;
      }
      if (
        where.span.length != 2 ||
        !Array.prototype.isPrototypeOf(where.span)
      ) {
        this.$message.warning('请选择分析时间范围');
        return false;
      }

      where.span = where.span.join(',');

      if (this.loading) return false;
      this.loading = true;
      submit(where)
        .then(res => {
          if (res.data.state) {
            this.$message.success('报告生成中，跳转至分析报告查看');
            this.timeoutFlag = setTimeout(() => {
              this.$emit('changeTab', 'report');
            }, 2000);
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(res => {
          this.$message.error('生成分析报告失败，服务错误');
        });
    },
    dateRange(date) {
      return dateRangeDisabled(date);
    },
    typeChange(type) {
      for (let i in this.typeClass) {
        this.typeClass[i] = '';
      }
      this.typeClass[type] = 'checked';
      this.where.type = type;
    }
  }
};
</script>
<style scoped>
.fxbgsc .form-box {
  background: none;
  padding: 0;
}
.fxbgsc >>> .el-form-item--small.el-form-item {
  margin-bottom: 20px;
}
.fxwd .el-button:hover,
.fxwd .checked {
  color: #fff;
}
.w-310 {
  width: 310px;
}
/*信  息  总  量：*/
.xxzl {
  background: #f7f7f7;
  border-radius: 6px;
  line-height: 35px;
  overflow: hidden;
  width: 310px;
  font-size: 12px;
}
.xxzl ul {
  width: 270px; /*height:75px;*/
  margin: 10px 20px;
}
.xxzl .ico {
  float: left;
  width: 14px;
  height: 14px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin: 11px 10px 0 0;
  display: inline-block;
}
.xxzl .ico3 {
  background-image: url(../../assets/images/overview/xxzl_ico3.svg);
}
.xxzl .ico4 {
  background-image: url(../../assets/images/overview/xxzl_ico4.svg);
}
.el-range-editor--small.el-input__inner {
  padding: 3px 5px;
}
.xxzl .iconfont {
  font-size: 16px;
  float: left;
  margin-right: 5px;
}
</style>
