<template>
  <el-container>
    <!-- 分析报告查看 -->
    <div class="w-100" v-loading="loading">
      <el-row class="empty" v-if="empty && !loading">
        <div>
          <img
            src="@/assets/images/common/empty/fxbgck.svg"
            alt="方案管理"
            width="400"
            class="img"
            type="image/svg+xml"
          />
          <p>
            <span>什么都没有，快来生成分析报告吧</span>
            <el-button type="primary" round size @click="toGenerate"
              >生成报告</el-button
            >
          </p>
        </div>
      </el-row>
      <div v-if="loading || !empty">
        <ul class="analysis-list mod-list lh-60 average-list">
          <li>
            <h3>
              <span class="num-tit">序号</span>
              <span class="tag">报告名称</span>
              <span class="tag">方案名称</span>
              <span class="tag">生成时间</span>
              <span class="tag w-300">分析时间范围</span>
              <span class="tag">报告类型</span>
              <span class="tag">创建者</span>
              <span class="tag w-100">状态</span>
              <span class="tag"
                ><i class="b" style="padding-left:30px;">操作</i></span
              >
            </h3>
          </li>
          <li v-for="(row, index) in data" :key="index" class="item">
            <span class="num bg-555" v-if="index == 0">1</span>
            <span class="num bg-689" v-else-if="index == 1">2</span>
            <span class="num bg-8ea" v-else-if="index == 2">3</span>
            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
            <span class="tag">{{ row.name }}</span>
            <span class="tag">{{ row.plan_name }}</span>
            <span class="tag">{{ row.updated }}</span>
            <span class="tag w-300">{{ row.span }}</span>
            <span class="tag">{{ row.type }}</span>
            <span class="tag">{{ row.created_name }}</span>
            <span class="tag w-100">
              <var v-if="row.state == 'running'"
                ><i class="iconfont icon-Loading Rotation"></i> 生成中</var
              >
              <var v-else-if="row.state == 'waiting'">生成队列中</var>
              <var v-else-if="row.state == 'error'">生成失败</var>
              <var v-else-if="row.state == 'done'">已生成</var>
            </span>
            <span class="tag operator">
              <!-- 查看 -->
              <!-- <var :class="{ tag: true, jy: row.state !== 'done' }"> -->
              <var :class="{ jy: row.state !== 'done' }">
                <el-tooltip effect="dark" content="预览报告" placement="bottom"
                  ><i
                    class="iconfont icon-chakan"
                    @click="toDetail(row)"
                    v-if="row.state === 'done'"
                  ></i>
                  <i class="iconfont icon-chakan" v-else></i
                ></el-tooltip>
              </var>
              <!-- 下载 -->
              <!-- <var :class="{ tag: true, jy: row.state !== 'done' }"> -->
              <var :class="{ jy: row.state !== 'done' }">
                <el-dropdown v-if="row.state === 'done'">
                  <span class="el-dropdown-link">
                    <i class="iconfont icon-xiazai"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <i
                        class="iconfont icon-pdf"
                        @click="downloadFile(row, 'pdf')"
                        ><span>导出PDF</span></i
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <i
                        class="iconfont icon-word"
                        @click="downloadFile(row, 'word')"
                        ><span>导出word</span></i
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <i
                        class="iconfont icon-excel"
                        @click="downloadFile(row, 'excel')"
                        ><span>导出excel</span></i
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-else>
                  <span class="el-dropdown-link">
                    <i class="iconfont icon-xiazai"></i>
                  </span>
                  <el-dropdown-menu> </el-dropdown-menu>
                </el-dropdown>
              </var>
              <!-- 删除 -->
              <var
                :class="{
                  jy: ['error', 'done'].indexOf(row.state) === -1
                }"
              >
                <!-- <var :class="{ tag: true,jy: ['error', 'done'].indexOf(row.state) === -1}"> -->
                <el-tooltip effect="dark" content="删除报告" placement="bottom"
                  ><i
                    class="iconfont icon-shanchu"
                    @click="del(row.id, index)"
                    v-if="['error', 'done'].indexOf(row.state) !== -1"
                  ></i>
                  <i class="iconfont icon-shanchu" v-else></i
                ></el-tooltip>
              </var>
            </span>
          </li>
        </ul>
      </div>
      <div class="page-bottom">
        <el-pagination
          :hide-on-single-page="singPage"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <confirm-delete
      class="single-information"
      ref="confirm"
      title="删除分析报告"
      confirm="删除"
      @must="mustDel"
    />
  </el-container>
</template>
<script type="application/javascript">
import {
  lists,
  deleteRow,
  state as reportState,
  retry as retryAnalysis
} from '@/api/analysis';
import confirmDelete from '@components/common/confirm.vue';
import { getAuthToken } from '@/utils/helpers';
import { mapGetters } from 'vuex';
const { _ } = window;

export default {
  name: 'analysis-list',
  components: {
    confirmDelete
  },
  props: {
    changeTab: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      data: [],
      currentPage: 1,
      size: 10,
      total: 0,
      loading: false,
      empty: true,
      nf: null,
      singPage: true
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters(['analysisPollFlag'])
  },
  methods: {
    loadData(disableLoading = false) {
      if (!disableLoading) this.loading = true;
      const _this = this;
      lists({ page: this.currentPage })
        .then(res => {
          if (res.data.state) {
            let result = res.data.data;
            this.data = result.data.map(row => {
              switch (row.type) {
                case 'hot':
                  row.type = '热点分析';
                  break;
                case 'event':
                  row.type = '事件传播分析-' + row.media;
                  break;
                case 'emotion':
                  row.type = '情感分析';
                  break;
                case 'opinion':
                  row.type = '观点分析';
                  break;
              }
              row.download = false;
              return row;
            });
            this.total = result.page.total;
            this.loading = false;
            this.empty = _.size(result.data) < 1;

            if (!this.analysisPollFlag) {
              const closeNf = () => {
                if (this.nf) {
                  this.nf.close();
                }
              };

              const data = _.orderBy(result.data, ['id', 'asc']);
              _(data).forEach(item => {
                if (item.state === 'waiting' || item.state === 'running') {
                  this.$store.commit(
                    'SET_ANALYSIS_POLL_FLAG',
                    setInterval(() => {
                      reportState({ id: item.id })
                        .then(res => {
                          if (res.data.state) {
                            const { data } = res.data;
                            if (data.state === 'error') {
                              clearInterval(_this.analysisPollFlag);
                              _this.$store.commit(
                                'SET_ANALYSIS_POLL_FLAG',
                                null
                              );
                              _this.nf = _this.$notify({
                                type: 'error',
                                title: '失败',
                                dangerouslyUseHTMLString: true,
                                message: _this.renderFail(item),
                                offset: 80,
                                duration: 0,
                                onClick: closeNf
                              });
                            } else if (data.state === 'done') {
                              clearInterval(_this.analysisPollFlag);
                              _this.$store.commit(
                                'SET_ANALYSIS_POLL_FLAG',
                                null
                              );
                              _this.loadData();
                              _this.nf = _this.$notify({
                                type: 'success',
                                title: '成功',
                                dangerouslyUseHTMLString: true,
                                message: this.renderSucc(),
                                offset: 80,
                                duration: 0,
                                onClick: closeNf
                              });
                            }
                          }
                        })
                        .catch(e => {
                          clearInterval(_this.analysisPollFlag);
                          window.console.error(e);
                        });
                    }, 6000)
                  );
                  return false;
                }
              });
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载报告列表失败，服务错误');
        });
    },
    renderSucc() {
      return (
        <p>
          <span class="cor-67c">报告生成成功。</span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.toHistory();
            }}
          >
            点击查看
          </span>
        </p>
      );
    },
    renderFail(item) {
      return (
        <span class="cor-fc5">
          <span>报告“{item.name}”生成失败，请稍后</span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.retry(item.id);
            }}
          >
            重试
          </span>
          ，或联系相关人员。
        </span>
      );
    },
    retry(id) {
      retryAnalysis({ id })
        .then(res => {
          if (res.data.state) {
            this.$message.success('重新提交该分析报告成功');
            setTimeout(() => {
              this.toHistory();
            }, 2000);
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          this.$message.error('重试失败，服务错误');
          window.console.error(e);
        });
    },
    toHistory() {
      this.loadData();

      if (this.$route.name === 'analysis') {
        if (this.changeTab) {
          this.changeTab('report');
        }
      } else {
        this.$router.push({ name: 'analysis', params: { toList: true } });
      }
    },
    downloadFile(row, type) {
      const token = getAuthToken();
      window.open(
        `${window.service.api}/analysis/convert/doc?id=${row.id}&type=${type}&token=${token}`,
        '_blank'
      );
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    toGenerate() {
      this.$emit('changeTab', 'generate');
    },
    del(id, index) {
      this.$refs.confirm.show(
        [{ type: 'error', text: '您将不能再查看及导出此份报告' }],
        { id, index }
      );
      return false;
    },
    mustDel(data) {
      const { id, index } = data;
      deleteRow(id)
        .then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            if (this.data.length == 1) {
              this.loadData();
            } else {
              this.data.splice(index, 1);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        })
        .catch(err => {
          this.$message.error('删除简报失败，服务错误');
        });
    },
    toDetail(row) {
      if (row.version == 1) {
        this.$message.warning(
          'V1版本舆情分析报告现已不支持查看，请于客服联系。'
        );
        return false;
      }
      let version = '_v' + row.version.toString();
      switch (row.type) {
        case '热点分析':
          // 改成复选
          window.open(`/analysis/hot${version}?id=${row.id}`, '_blank');
          break;
        case '事件传播分析-微博':
          window.open(
            `/analysis/propagation_weibo${version}?id=${row.id}`,
            '_blank'
          );
          break;
        case '事件传播分析-网媒':
          window.open(`/analysis/propagation${version}?id=${row.id}`, '_blank');
          break;
        case '情感分析':
          window.open(`/analysis/emotion${version}?id=${row.id}`, '_blank');
          break;
        case '观点分析':
          window.open(`/analysis/view${version}?id=${row.id}`, '_blank');
          break;
      }
    }
  }
};
</script>
<style scoped>
.analysis-list .num {
  margin-top: 13px;
}
.analysis-list.average-list .operator var {
  float: left;
  margin-left: 30px;
}
</style>
