<template>
  <el-container>
    <div class="w-100 analysis_total">
      <div class="content">
        <el-card class="box-card">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="分析报告生成" name="generate">
              <analysis-generate v-if="activeName === 'generate'" @changeTab="changeTab" />
            </el-tab-pane>
            <el-tab-pane label="分析报告查看" name="report">
              <analysis-list v-if="activeName === 'report'" :changeTab="changeTab" />
            </el-tab-pane>
            <el-tab-pane label="分析模版案例" name="case">
              <analysis-case v-if="activeName === 'case'" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <p class="jszc_text" v-if="version === 'shwj'">技术支持：上海索思数据科技有限公司 </p>
  </el-container>
</template>
<script type="application/javascript">
import Generate from './generate.vue'
import List from './list.vue'
import Case from './case.vue'

export default {
  name: 'analysis-analysis',
  components: {
    'analysis-generate': Generate,
    'analysis-list': List,
    'analysis-case': Case
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      activeName: 'generate'
    }
  },
  mounted() {
    if (this.$route.params.hasOwnProperty('toList')) {
      this.changeTab('report')
    }
  },
  methods: {
    handleClick(tab, event) {},
    changeTab(index) {
      this.activeName = index
    }
  }
}
</script>
<style scoped>
</style>
