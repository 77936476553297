<template>
  <el-container class="w-100">
    <div class="min-h-600 case">
      <h3>热点分析</h3>
      <ul class="img_list">
        <!-- <li><a href="/analysis/hot_demo" target="_blank" class="rdfx">李彦宏被泼水</a></li> -->
        <li><a href="/analysis/hot_demo" target="_blank" class="rdfx">郑州特大暴雨</a></li>
        <li><a href="/analysis/hot_demo_v3" target="_blank" class="rdfx">12345</a></li>
      </ul>
      <h3>事件传播分析</h3>
      <ul class="img_list">
        <li><a href="/analysis/propagation_weibo_demo" target="_blank" class="sjcb_wb">保时捷女</a></li>
        <li><a href="/analysis/propagation_demo" target="_blank" class="sjcb_wx">新城控股</a></li>
      </ul>
      <h3>观点分析</h3>
      <ul class="img_list">
        <li><a href="/analysis/view_demo" target="_blank" class="gdfx">香港暴乱</a></li>
      </ul>
      <h3>情感分析</h3>
      <ul class="img_list">
        <!-- <li><a href="/analysis/emotion_demo" target="_blank" class="qgfx">新城控股</a></li> -->
        <li><a href="/analysis/emotion_demo" target="_blank" class="qgfx">海地总统刺杀</a></li>
      </ul>
    </div>
  </el-container>
</template>
<script>
export default {
  name: 'analysis-case',
  props: {
    msg: String
  }
}
</script>
<style scoped>
.case h3{font-size: 14px;line-height:50px;}
.case .img_list{overflow: hidden;width: 100%;margin-bottom: 20px;}
.case .img_list li{margin-right:30px;display: inline;float:left;}
.case .img_list a{width: 120px;height: 158px;display: block;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;line-height:200px;text-align: center;font-size: 12px;}
.rdfx{background-image:url(../../assets/images/analysis/rdfx.png);}
.case .img_list .sjcb_wb{background-image:url(../../assets/images/analysis/sjcbfx_wb.png);line-height:168px;}
.case .img_list .sjcb_wx{background-image:url(../../assets/images/analysis/sjcbfx_wm.png);line-height:168px;}
.case .img_list .gdfx{background-image:url(../../assets/images/analysis/gdfx.png);line-height:140px;}
.case .img_list .qgfx{background-image:url(../../assets/images/analysis/qgfx.png);line-height:140px;}
</style>
